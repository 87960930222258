import React from 'react'

import { makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import Box from '@material-ui/core/Box'
import Container from '@material-ui/core/Container'
import { GatsbyImage, getImage, withArtDirection } from 'gatsby-plugin-image'
import { renderRichText } from 'gatsby-source-contentful/rich-text'
import options from '../../templates/richTextRenderers'
import Typography from '@material-ui/core/Typography'

import ButtonSelector from '../../components/ButtonSelector'

const useStyles = (props) =>
    makeStyles((theme) => ({
        wrapper: {
            paddingRight: 0,
            paddingLeft: 0,
            backgroundColor: '#EEE9E580',
            borderRadius: '4%',
        },
        core: {
            paddingTop: '30px',
            paddingRight: '30px',
            paddingLeft: '30px',
            [theme.breakpoints.down('sm')]: {
                paddingLeft: '30px',
            },
        },
        image: {
            borderRadius: '5%',
            marginTop: '-5%',
            // marginLeft: '-6%',
            [theme.breakpoints.down('md')]: {
                borderRadius: '0%',
                marginTop: '0%',
                marginLeft: '0%',
                width: '820px',
                height: '315px',
            },
            [theme.breakpoints.down('sm')]: {
                borderRadius: '0%',
                marginTop: '0%',
                marginLeft: '0%',
                width: '390px',
                height: '293px',
            },
        },
        image2: {
            borderRadius: '5%',
            marginTop: '-5%',
            // marginLeft: '13%',
            paddingLeft: '8%',
            [theme.breakpoints.down('md')]: {
                borderRadius: '0%',
                marginTop: '0%',
                marginLeft: '0%',
                width: '820px',
                height: '315px',
            },
            [theme.breakpoints.down('sm')]: {
                borderRadius: '0%',
                marginTop: '0%',
                marginLeft: '0%',
                width: '390px',
                height: '293px',
            },
        },
        icon1: {
            [theme.breakpoints.down('sm')]: {
                width: '104px',
                height: '20px',
                textAlign: 'right',
            },
        },
        icon2wrapper: {
            textAlign: 'right',
            [theme.breakpoints.down('sm')]: {
                width: '115px',
                height: '30px',
            },
        },
        buttonPrimary: {
            width: '100%',
            height: '4.5rem',
            borderRadius: '0.938rem',
            color: theme.palette.text.white,
            fontSize: '1.25rem',
            margin: '20px 0px',
            background:
                'linear-gradient(138.25deg, #FFAF75 7%, #F45F00 95.28%)',
            '&:hover': {
                background:
                    'linear-gradient(138.25deg, rgba(255, 175, 117, 0.5) 7%, rgba(244, 95, 0, 0.5) 95.28%)',
            },
            [theme.breakpoints.down('sm')]: {
                width: '85%',
                height: '2.5rem',
                fontSize: '0.875rem',
                lineHeight: '1.125rem',
                margin: '24px 0px',
            },
        },
        buttonSecondary: {
            width: '100%',
            height: '4.5rem',
            borderRadius: '0.938rem',
            color: theme.palette.text.white,
            fontSize: '1.25rem',
            margin: '20px 0px',
            background:
                'linear-gradient(152.51deg, #14FFD7 -64.25%, #75CCBD 105.81%)',
            '&:hover': {
                background:
                    'linear-gradient(152.51deg, rgba(20, 255, 215, 0.5) -64.25%, rgba(117, 204, 189, 0.5))',
            },
            [theme.breakpoints.down('sm')]: {
                width: '85%',
                height: '2.5rem',
                fontSize: '0.875rem',
                lineHeight: '1.125rem',
                margin: '24px 0px',
            },
        },
        title: {
            fontSize: '1.75rem',
            fontWeight: 600,
            [theme.breakpoints.down('sm')]: {
                fontSize: '0.875rem',
            },
        },
        text: {
            fontSize: '1.5rem',
            color: '#0F051899',
            [theme.breakpoints.down('sm')]: {
                fontSize: '0.875rem',
            },
        },
    }))

const getOrientation = (input) =>
    input === 'Image(left) -> Text(right)' ? 'row' : 'row-reverse'

const TutorCard = (props) => {
    const data = props.data[props.componentId]
    const classes = useStyles({ ...props, data })()

    const direction = getOrientation(data.orientation)

    const hasButton = Boolean(data.button)
    const hasImage = Boolean(data.image)
    const hasText = Boolean(data.text)
    const hasIcon1 = Boolean(data.icon1)
    const hasIcon2 = Boolean(data.icon2)

    const image = withArtDirection(getImage(data.image), [
        {
            media: '(max-width: 600px)',
            image: getImage(data.mobileImage),
        },
    ])

    const icon1 = getImage(data.icon1)
    const icon2 = getImage(data.icon2)

    const adjustOrientation = (input) =>
        input === 'Image(left) -> Text(right)' ? classes.image : classes.image

    return (
        <Box py={3} pb={7}>
            <Container maxWidth="lg" className={classes.wrapper}>
                <Grid container direction={direction}>
                    <Grid item xs={12} md={12} lg={4}>
                        {hasImage && (
                            <GatsbyImage
                                image={image}
                                alt={data.image.title}
                                className={adjustOrientation(data.orientation)}
                            />
                        )}
                    </Grid>
                    <Grid
                        item
                        container
                        xs={12}
                        md={12}
                        lg={8}
                        className={classes.core}
                    >
                        <Grid item container xs={12}>
                            <Grid item xs={6}>
                                <Typography className={classes.title}>
                                    {data.name}
                                </Typography>
                            </Grid>
                            <Grid item xs={6} className={classes.icon2wrapper}>
                                {hasIcon2 && (
                                    <GatsbyImage
                                        image={icon2}
                                        alt={data.icon2.title}
                                    />
                                )}
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            {hasIcon1 && (
                                <GatsbyImage
                                    image={icon1}
                                    alt={data.icon1.title}
                                    className={classes.icon1}
                                />
                            )}
                        </Grid>
                        <Grid item xs={12}>
                            {hasText && (
                                <Typography className={classes.text}>
                                    {renderRichText(data.text, options())}
                                </Typography>
                            )}
                            {hasButton &&
                                data &&
                                data.button &&
                                data.button.map((button) => {
                                    const buttonColor = (color) => {
                                        if (color === 'Orange') {
                                            return classes.buttonPrimary
                                        }
                                        return classes.buttonSecondary
                                    }
                                    const theButtonColor = buttonColor(
                                        button.buttonColor
                                    )
                                    const hasTagClass = button.tagClass
                                        ? button.tagClass
                                        : ''
                                    return (
                                        <Grid
                                            item
                                            xs={12}
                                            md={3}
                                            className={classes.buttonWrapper}
                                        >
                                            <ButtonSelector
                                                className={`${theButtonColor} ${hasTagClass}`}
                                                data={button}
                                            >
                                                {button.label}
                                            </ButtonSelector>
                                        </Grid>
                                    )
                                })}
                        </Grid>
                    </Grid>
                </Grid>
            </Container>
        </Box>
    )
}

export default TutorCard
